import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./sba-approved-lenders.css"
import { Link } from "gatsby"

const BusinessAssistance = () => {
  return (
    <Layout>
      <SEO title="Business Assistance" />
      <div className="container primary-bg">
        <h1 class="small-padding">Business Assistance</h1>
        <p className="small-padding">
          Hands-on assistance to small businesses with the application process for C.A.R.E.S. Act loans/grants, and potentially other funding. Guidance includes identifying required documentation and how to access it, possible review of small business packages prior to submitting them, and the process for submitting.
        </p>
        <hr />
        <Link className="full-cta" to="/cares-act-and-other-loan-prep">
          CARES Act and Other Loan Prep
        </Link>
        <Link className="full-cta" to="/growth-marketing">
          Growth Marketing
        </Link>
        <Link className="full-cta" to="/international-business">
          International Business
        </Link>
        <Link className="full-cta" to="/micro-bridge-funding">
          Micro Bridge Funding
        </Link>
        <Link className="full-cta" to="/private-partners">
          Private Pro Bono Partners
        </Link>
        <Link className="full-cta" to="/procurement">
          Procurement
        </Link>
        <Link className="full-cta" to="/technology-maximization">
          Technology Maximization
        </Link>
        <hr />
      </div>
    </Layout>
  )
}

export default BusinessAssistance
